<template>
  <v-row class="d-flex flex-column">
    <v-col>
      <v-row>
        <v-breadcrumbs :items="breadcrumbs" class="px-4">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
            >
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>

      <v-form
        ref="form"
        v-model="form.valid"
        lazy-validation>
        <v-row class="pt-1">
          <v-col cols="12">
            <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-0 mt-3 mb-3"
            >
              {{ ui.alert.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" sm="4" class="pt-0 d-xs-none">
            <v-card outlined
                    class="rounded-lg overflow-hidden">
              <v-row
                style="border-radius: 0"
                class="blue py-6 pb-9 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="d-flex flex-column title text-center white--text pl-0">
                    <v-icon class="my-10" size="125">mdi-account</v-icon>
                    {{
                      (_.isEmpty(form.data.lastname) || _.isEmpty(form.data.firstname) ? $vuetify.lang.t('$vuetify.administration.users.add.ui.default.name') : (_.isEmpty(form.data.sex) ? '' : $vuetify.lang.t('$vuetify.global.sex_label.' + form.data.sex)) + ' ' + form.data.lastname + ' ' + form.data.firstname)
                    }}
                  </v-toolbar-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" class="pt-0">
            <v-card outlined
                    :loading="ui.loading"
                    :disabled="ui.disabled"
                    class="rounded-lg overflow-hidden">
              <template slot="progress">
                <v-progress-linear
                  color="blue-grey darken-3"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-row
                style="border-radius: 0"
                class="blue py-6 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="title white--text pl-0">
                    {{ $vuetify.lang.t('$vuetify.administration.users.add.ui.title') }}
                  </v-toolbar-title>
                </v-col>
              </v-row>
              <v-card-text class="px-0 pb-0">
                <div class="px-5 px-sm-9 pt-6">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="form.data.sex"
                        item-text="name"
                        item-value="value"
                        :items="sexes"
                        :label="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.sex.label')+'*'"
                        :rules="form.rules.sex"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.data.lastname"
                        :counter="45"
                        :label="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.lastname.label')+'*'"
                        :rules="form.rules.lastname"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.data.firstname"
                        :counter="30"
                        :label="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.firstname.label')+'*'"
                        :rules="form.rules.firstname"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :counter="64"
                        :label="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.email.label')+'*'"
                        :rules="form.rules.email"
                        v-model="form.data.email"
                        required
                      >
                        <v-icon
                          slot="prepend"
                        >
                          mdi-email
                        </v-icon>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="form.data.password1"
                            :counter="100"
                            :append-icon="form.ui.show_password1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="form.rules.password1"
                            :type="form.ui.show_password1 ? 'text' : 'password'"
                            :label="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.password1.label')+'*'"
                            :hint="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.password1.hint')"
                            @click:append="form.ui.show_password1 = !form.ui.show_password1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="form.data.password2"
                            :counter="100"
                            :append-icon="form.ui.show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="form.rules.password2"
                            :type="form.ui.show_password2 ? 'text' : 'password'"
                            :label="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.password2.label')+'*'"
                            :hint="$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.password2.hint')"
                            @click:append="form.ui.show_password2 = !form.ui.show_password2"
                          ></v-text-field>
                        </v-col>

                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="mt-6 pb-0 mb-0 text-caption">
                        {{ $vuetify.lang.t('$vuetify.global.form.required_fields') }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions class="d-xs-block px-3 px-sm-7">
                <v-btn
                  text
                  v-on:click="reset()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  v-on:click="submit()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey lighten-1"
              fab
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'AdminUsersList'}">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.back') }}</span>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </v-row>
</template>
<script>
import _ from 'lodash'

export default {
  data: () => ({
    sexes: [],
    districts: ['Nouvelle Aquitaine'],
    breadcrumbs: [],
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    form: {
      valid: false,
      ui: {
        show_password1: false,
        show_password2: false
      },
      data: {
        sex: '',
        lastname: '',
        firstname: '',
        email: '',
        password1: '',
        password2: ''
      },
      rules: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.form.ui.show_password1 = false
      this.form.ui.show_password2 = false
    },
    validate () {
      return this.$refs.form.validate()
    },
    async submit () {
      if (!this.validate()) {
        return false
      }

      // check password and fake request if is not same
      if (this.form.data.password1 !== this.form.data.password2) {
        this.ui.loading = true
        this.ui.disabled = true

        setTimeout(() => {
          this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.administration.users.add.notification.not_same_password'))

          this.ui.loading = false
          this.ui.disabled = false
        }, 500)
        return false
      }

      try {
        this.ui.loading = true
        this.ui.disabled = true

        await this.axios.post('/administration/users/add', {
          sex: this.form.data.sex,
          lastname: this.form.data.lastname,
          firstname: this.form.data.firstname,
          email: this.form.data.email,
          password: this.form.data.password1,
          confirm: this.form.data.password2
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        this.reset()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.administration.users.add.notification.added'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.administration.users.add.notification.fail'))
              break

            case 302:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.administration.users.add.notification.email_exist'))
              break

            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.administration.users.add.notification.unknown'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    }
  },
  watch: {
    'form.data.lastname': function (newVal, oldVal) {
      this.form.data.lastname = _.startCase(newVal)
    },
    'form.data.firstname': function (newVal, oldVal) {
      this.form.data.firstname = _.startCase(newVal)
    }
  },
  mounted () {
    this.ui.loading = true
    this.ui.disabled = true
    setTimeout(() => {
      this.reset()
      this.ui.loading = false
      this.ui.disabled = false
    }, 1000)
  },
  created () {
    // Define translations
    this.sexes = [
      {
        value: 'male',
        name: this.$vuetify.lang.t('$vuetify.global.sex_label.male')
      }, {
        value: 'female',
        name: this.$vuetify.lang.t('$vuetify.global.sex_label.female')
      }, {
        value: 'other',
        name: this.$vuetify.lang.t('$vuetify.global.sex_label.other')
      }
    ]
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.administration.name'),
        disabled: true,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.administration.users.name'),
        disabled: false,
        to: { name: 'AdminUsersList' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.administration.users.add.name'),
        disabled: true,
        to: { name: 'AdminUsersEdition' }
      }
    ]
    this.form.rules = {
      sex: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => _.includes(['male', 'female', 'other'], value) || 'La civilité choisie n\'est pas autorisé.'
      ],
      lastname: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required')
      ],
      firstname: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required')
      ],
      email: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => {
          if (_.size(value) <= 0) {
            return true
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (pattern.test(value)) || this.$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.email.rules.invalid')
        }
      ],
      password1: [
        value => (_.size(value) >= 8 && _.size(value) <= 100) || this.$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.password2.rules.min')
      ],
      password2: [
        value => (_.size(value) >= 8 && _.size(value) <= 100) || this.$vuetify.lang.t('$vuetify.administration.users.add.ui.form.fields.password2.rules.min')
      ]
    }
  }
}
</script>
